<div class="container center-app">
  <h3 *ngIf="isArticle">Creer ou mise à jour d'un article</h3>
  <h3 *ngIf="isFormation">Creer ou mise à jour d'une formation</h3>
  <h3 *ngIf="isPrestation">Creer ou mise à jour d'une prestation</h3>
  <div class="row">
    <form [formGroup]="createFormationForm" (ngSubmit)="save()">
      <div class="mb-3">
        <div class="form-group">
          <label for="description" class="form-label">Titre:</label>
          <input
            type="text"
            formControlName="title"
            class="form-control"
            [ngClass]="{ 'is-invalid': f['title'].errors }"
          />
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label for="description" class="form-label">Description:</label>
          <ngx-editor-menu
            *ngIf="isPlatformBrowser"
            id="text"
            [editor]="editorDescription"
            [toolbar]="toolbar"
            placeholder="Saisez votre description ici"
          >
          </ngx-editor-menu>

          <ngx-editor
            *ngIf="isPlatformBrowser"
            id="description"
            [editor]="editorDescription"
            formControlName="description"
            [ngClass]="{ 'is-invalid': f['description'].errors }"
          >
          </ngx-editor>
        </div>
      </div>

      <div class="mb-3">
        <div class="form-group">
          <label for="headerImage" class="form-label"
            >Image d'entête ( Optionnel)</label
          >
          <button
            type="button"
            (click)="openFileDialogHeader()"
            title="Upload Image"
          >
            <i class="fa-regular fa-image"></i>
          </button>
          <ngx-editor
            *ngIf="isPlatformBrowser"
            id="header"
            [editor]="editorHeader"
            formControlName="headerImage"
            placeholder="Selectionner votre image d'entete"
          >
          </ngx-editor>
          <input
            #fileInputHeader
            type="file"
            (change)="onFileChange($event, 'headerImage')"
            accept="image/*"
            style="display: none"
          />
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label for="text" class="form-label">Votre article</label>
          <ngx-editor-menu
            *ngIf="isPlatformBrowser"
            id="text"
            [editor]="editor"
            [toolbar]="toolbar"
            placeholder="Saisez votre article ici"
          >
          </ngx-editor-menu>

          <input
            #fileInput
            type="file"
            (change)="onFileChange($event, 'text')"
            accept="image/*"
            style="display: none"
          />

          <!-- Bouton pour charger une image -->
          <button type="button" (click)="openFileDialog()" title="Upload Image">
            <i class="fa-regular fa-image"></i>
          </button>
          <span> </span>
          <!-- Bouton pour charger un PDF -->
          <button type="button" (click)="openPdfDialog()" title="Upload PDF">
            <i class="fa-solid fa-file-pdf"></i>
          </button>

          <!-- Bouton pour charger une vidéo 
          <button
            type="button"
            (click)="openVideoDialog()"
            title="Upload Video"
          >
            <i class="material-icons">videocam</i>
          </button>
          -->

          <ngx-editor
            *ngIf="isPlatformBrowser"
            id="text"
            [editor]="editor"
            formControlName="text"
          >
          </ngx-editor>

          <!-- Input de fichier caché pour le chargement d'image -->
          <input
            #fileInput
            type="file"
            (change)="onFileChange($event, 'text')"
            accept="image/*"
            style="display: none"
          />
          <!-- Input de fichier caché pour le chargement de PDF -->
          <input
            #pdfInput
            type="file"
            (change)="onPdfChange($event)"
            accept="application/pdf"
            style="display: none"
          />

          <!-- Input de fichier caché pour le chargement de vidéo -->
          <input
            #videoInput
            type="file"
            (change)="onVideoChange($event)"
            accept="video/*"
            style="display: none"
          />
        </div>
      </div>

      <div class="mb-3">
        <div class="form-group">
          <label for="headerImage" class="form-label">Catégorie:</label>
          <select
            formControlName="category"
            [ngClass]="{ 'is-invalid': f['category'].errors }"
          >
            <option [ngValue]="null" disabled>Quelle catégorie ?</option>
            <option *ngFor="let category of categories" [ngValue]="category">
              {{ category }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <div class="editor-tag" (click)="focusTagInput()">
            <span class="tag-item" *ngFor="let tag of tags">
              {{ tag }}
              <span class="remove-tag" (click)="removeTag(tag)">×</span>
            </span>
            <input
              placeholder="Ajouter un tag"
              #tagInput
              type="text"
              class="input-tag"
              (keyup)="onKeyUp($event)"
              formControlName="tags"
            />
          </div>
        </div>

        <div class="mb-3">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="description" class="form-label">Prix:</label>
              <input
                type="text"
                formControlName="price"
                class="form-control"
                [ngClass]="{ 'is-invalid': f['price'].errors }"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <button
    [disabled]="createFormationForm.invalid"
    type="button"
    class="btn btn-sm btn-primary mb-2 mr-sm-2"
    (click)="publish()"
  >
    Publier
  </button>

  <button
    [disabled]="createFormationForm.invalid"
    type="button"
    class="btn btn-sm btn-secondary mb-2 mr-sm-2"
    (click)="save()"
  >
    Sauvegarder en brouillon
  </button>
</div>
