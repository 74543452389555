import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { Editor, NgxEditorModule, Toolbar, } from 'ngx-editor';

// from main module


import { User } from '../../model/user';
import { AuthService } from '../../pages/auth/auth.service';
import { AxiosService } from '../../service/axios.service';
import { Formation } from '../../model/formation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../constants/Constant';

@Component({
  selector: 'app-editor',
  standalone: true,
  imports: [CommonModule, RouterLink,
    RouterOutlet, ReactiveFormsModule, NgxEditorModule
  ],
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})


export class EditorComponent implements OnInit,OnDestroy  {

  @Input() document! : string;
  
  isArticle : boolean = false;
  isFormation : boolean = false;
  isPrestation : boolean = false;

  currentUser: User =  {};
  errors: any;

  data: any =  {};

  @Input() control: FormControl =  new FormControl();

  createFormationForm = new FormGroup({
    id: new FormControl(''),
    text: new FormControl(''),
    title: new FormControl(''),
    description: new FormControl(''),
    authorId:new FormControl(),
    formationId:new FormControl(),
    articleId:new FormControl(),
    prestationId:new FormControl(),
    headerImage: new FormControl(),
    category: new FormControl(),
    tags: new FormControl(),
    status : new FormControl(),
    visibility: new FormControl(),
    countViews: new FormControl(),
    topView: new FormControl(),
    price: new FormControl()
  });

  blured = false;
  focused = false;
  submitted = false;

  categories = Constants.tags

  @ViewChild('tagInput') tagInputRef?: ElementRef  ;
  tags: string[] = ['html', 'Angular'];

  

  id?: number ;

  isBrowser: boolean = false;
  editorConfig: any;


  editor!: Editor;
  editorHeader!: Editor;
  editorDescription!: Editor;
  
  toolbar: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["code", "blockquote"],
    ["ordered_list", "bullet_list"],
    [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"],
    ['horizontal_rule', 'format_clear', 'indent', 'outdent'],
    ['superscript', 'subscript'],
    ['undo', 'redo'],
  ];


  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInputHeader') fileInputHeader!: ElementRef<HTMLInputElement>;
  @ViewChild('pdfInput') pdfInput!: ElementRef<HTMLInputElement>;
  @ViewChild('videoInput') videoInput!: ElementRef<HTMLInputElement>;

  isPlatformBrowser =  false;

  constructor( private fb: FormBuilder, @Inject(PLATFORM_ID) private platformId: object, 
            private authService: AuthService, private axiosService: AxiosService,
            private spinner: NgxSpinnerService, private toastrService : ToastrService) { }

 
  private route = inject(ActivatedRoute);



  ngOnInit() {
    switch(this.document){
      case 'articles':
        this.isArticle = true;
        break;
      
        case 'formations':
        this.isFormation = true;
        break;

        case 'prestations':
          this.isPrestation = true;
          break;
    }
 
    this.isPlatformBrowser = isPlatformBrowser(this.platformId);

    if (this.isPlatformBrowser) {
      this.editor = new Editor();
      this.editorHeader = new Editor();
      this.editorDescription =  new Editor();
    }
    
    
    
    this.data.text = 'Saisissiez votre article ici';
    this.data.title = 'Titre de votre article';
    this.data.description = 'Résumé de votre article';
    this.data.title = 'Titre de votre article';

    this.route.params.subscribe(params => {
      this.id = params['id'];

      if(this.id != null){
        this.axiosService.request(
          "GET",
          "/"+this.document+"/" +this.id,
          {
          }).then(
          response => {
            if(response){
              this.data =  response.data;
              this.createFormationForm = this.initForm(this.data);
            }
             
          }).catch(
          error => {
            this.errors = error.response!.data;
          });
    }else{
      this.createFormationForm = this.initForm(this.data);
    }

    });

   
    this.currentUser = this.authService.getUser();
    

    if(this.currentUser){
      this.axiosService.request(
        "GET",
        "/users/" +this.currentUser.id,
        {
        }).then(
        response => {
           this.currentUser =  response.data;
        }).catch(
        error => {
          this.errors = error.response.data;
        }
    );
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.createFormationForm.controls;
  }


  openFileDialog(): void {
    this.fileInput.nativeElement.click();
  }

  openFileDialogHeader(): void {
    this.fileInputHeader.nativeElement.click();
  }
  

  onFileChange(event: Event, target : string): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imgTag = `<img src="${reader.result}" alt="Image" "width:200px";height:200px;" />`;
      let value = this.createFormationForm.get(target)?.value != null ? this.createFormationForm.get(target)?.value : '';
      this.createFormationForm
        .get(target)
        ?.setValue(value + imgTag);
    };
    reader.readAsDataURL(file);
  }

  openPdfDialog(): void {
    this.pdfInput.nativeElement.click();
  }

  onPdfChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const pdfLink = `<a href="${reader.result}" download="${file.name}">${file.name}</a>`;
      this.createFormationForm
        .get('text')
        ?.setValue(this.createFormationForm.get('text')?.value + pdfLink);
    };
    reader.readAsDataURL(file);
  }

  openVideoDialog(): void {
    this.videoInput.nativeElement.click();
  }

  onVideoChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const videoTag = `<video controls src="${reader.result}" alt="Video"></video>`;
      this.createFormationForm
        .get('text')
        ?.setValue(this.createFormationForm.get('text')?.value + videoTag);
    };
    reader.readAsDataURL(file);
  }

  publish() {
    this.submitted = true;
    this.createFormationForm.controls.authorId.setValue(this.currentUser.id); 
    let documentId  = this.createFormationForm.controls.title!.value!.replaceAll(" ", "-").replaceAll(/[^a-zA-Z ]/g, "-").replaceAll("--", "-").toLowerCase()+"-"+ Math.floor((Math.random() * 10) + 1);
    this.createFormationForm.controls.formationId.setValue(documentId); 
    this.createFormationForm.controls.articleId.setValue(documentId); 
    this.createFormationForm.controls.prestationId.setValue(documentId); 
    let tagsString = this.tags.map(e => e.replace(/\s/g, "")).join(",")
    this.createFormationForm.controls.tags.setValue(tagsString);
    this.createFormationForm.controls.status.setValue('PUBLISH'); 
    this.createFormationForm.controls.visibility.setValue('PUBLIC'); 

    this.createFormationForm.controls.countViews.setValue(this.createFormationForm.controls.countViews.value + 1); 
    this.createFormationForm.controls.topView.setValue(this.createFormationForm.controls.topView.value ? true : false); 
    this.createFormationForm.controls.price.setValue(this.createFormationForm.controls.price.value != null ? this.createFormationForm.controls.price.value: 0); 
     
    this.spinner.show();
    this.axiosService.request(
      "POST",
      "/"+this.document,
      this.createFormationForm.value).then(
      response => {
         this.spinner.hide();
         this.data =  response.data;
         this.createFormationForm = this.initForm(this.data);
      }).catch(
      error => {
        this.spinner.hide();
        this.errors = error.response.data;
      }
  );

  }
  save() {
    this.submitted = true;
    this.createFormationForm.controls.status.setValue('DRAFT'); 
    this.createFormationForm.controls.visibility.setValue('PRIVATE'); 
    this.createFormationForm.controls.authorId.setValue(this.currentUser.id);
    let documentId  = this.createFormationForm.controls.title!.value!.replaceAll(" ", "-").replaceAll(/[^a-zA-Z ]/g, "-").replaceAll("--", "-");
    this.createFormationForm.controls.formationId.setValue(documentId); 
    this.createFormationForm.controls.articleId.setValue(documentId);
    this.createFormationForm.controls.prestationId.setValue(documentId); 
    let tagsString = this.tags.map(e => e.replace(/\s/g, "")).join(",")
    this.createFormationForm.controls.tags.setValue(tagsString); 

    this.createFormationForm.controls.countViews.setValue(this.createFormationForm.controls.countViews.value + 1); 
    this.createFormationForm.controls.topView.setValue(this.createFormationForm.controls.topView.value ? true : false); 
    this.createFormationForm.controls.price.setValue(this.createFormationForm.controls.price.value != null ? this.createFormationForm.controls.price.value: 0); 

    this.spinner.show();

    this.axiosService.request(
      "POST",
      "/"+this.document,
      this.createFormationForm.value).then(
      response => {
         this.data =  response.data;
         this.createFormationForm = this.initForm(this.data);
         this.spinner.hide();
         this.toastrService.success("Votre "+this.document+ " a bien été crée/mise à jour.")
      }).catch(
      error => {
        this.spinner.hide();
        this.errors = error.response.data;
      }
  );

  }

  focusTagInput(): void {
    this.tagInputRef?.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.createFormationForm.controls.tags.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag();
      return;
    } else {
      if (event.code === 'Comma' || event.code === 'Space') {
        this.addTag(inputValue);
        this.createFormationForm.controls.tags.setValue('');
      }
    }
  }

  addTag(tag: string): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 ) {
      this.tags.push(tag);
    }
  }

  removeTag(tag?: string): void {
    if (tag) {
      this.tags.splice(this.tags.indexOf(tag));
    }
  }


  initForm(data : any) : FormGroup{
    return  this.fb.group({
      id: new FormControl(data.id),
      text: [data.text, [Validators.required]],
      title: [data.title, [Validators.required, Validators.maxLength(2000)]],
      description: [data.description, [Validators.required, Validators.maxLength(10000)]],
      authorId:new FormControl(data.authorId),
      formationId:new FormControl(data.formationId),
      articleId:new FormControl(data.articleId),
      prestationId:new FormControl(data.prestationId),
      headerImage: new FormControl(data.headerImage),
      category: new FormControl(data.category, [Validators.required]),
      tags: new FormControl(data.tags),
      status : new FormControl(data.status),
      visibility: new FormControl(data.visibility),
      countViews: new FormControl(data.countViews),
      topView: new FormControl(data.topView),
      price: new FormControl(data.price)
    });
  }


  ngOnDestroy(): void {
    if (this.isPlatformBrowser) {
      this.editor.destroy();
      this.editorHeader.destroy();
    }
  }
  
}

